<script>
/* eslint-disable  */
</script>
<template>
  <div>
    <v-container>
      <div class="d-flex flex-row align-center justify-space-between mb-4">
        <div class="top-header mb-0 text-uppercase">
          <v-icon class="cursor--pointer" @click="cancelEdit">mdi-reply</v-icon>

          {{ "Employees" }}
        </div>
      </div>
      <v-card>
        <v-card-title
          class="headline primary white--text align-center justify-space-between"
        >
          Update employee : {{ form.fullname ? form.fullname : "-" }}
        </v-card-title>
        <v-tabs v-model="tab" centered>
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1"> Employee Info </v-tab>

          <v-tab href="#tab-2"> Companies </v-tab>
          <v-tab href="#tab-3"> Payments </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-card-text class="pt-3">
              <v-form ref="form" v-model="formValid">
                <!--  ROl -->
                <v-radio-group v-model="form.rol" row mandatory>
                  <v-radio v-if="isSuper" label="Super" value="SUPER" />
                  <v-radio
                    v-if="isSuper || isAdmin"
                    :label="$t('crmCallCenter.labelAdmin')"
                    value="ADMIN"
                  />
                  <v-radio v-if="isSuper || isAdmin" label="Agent" value="WORKER" />
                  <v-radio
                    v-if="isSuper || isAdmin"
                    label="External Agent"
                    value="AGENT"
                  />
                  <v-radio v-if="isSuper || isAdmin" label="Doctor" value="DOCTOR" />
                  <v-radio
                    v-if="isSuper || isAdmin"
                    label="Supervisor"
                    value="SUPERVISOR"
                  />
                </v-radio-group>

                {{/* Fullname, username */}}
                <v-layout>
                  <v-text-field
                    v-model.trim="form.fullname"
                    class="mr-1"
                    :rules="[rules.maxCaracters, rules.required]"
                    :label="$t('labelFullName')"
                    dense
                    outlined
                    autofocus
                  />
                  <v-text-field
                    v-model.trim="form.username"
                    class="ml-1"
                    :rules="[rules.maxCaracters, rules.required]"
                    :label="$t('labelUsername')"
                    dense
                    maxlength="20"
                    outlined
                  />
                </v-layout>
                {{/* Email, phone */}}
                <v-layout>
                  <v-text-field
                    v-model.trim="form.phone"
                    class="mr-1"
                    type="tel"
                    autocomplete="tel"
                    :placeholder="$t('labelPhone')"
                    prepend-inner-icon="mdi-numeric-positive-1"
                    dense
                    outlined
                  />
                  <v-text-field
                    outlined
                    dense
                    style="max-width: 150px"
                    label="Ext"
                    v-model="form.ext"
                  ></v-text-field>
                  <v-text-field
                    v-model.trim="form.email"
                    class="ml-1"
                    placeholder="Email"
                    type="email"
                    outlined
                    dense
                  >
                    <v-icon slot="prepend-inner">mdi-email</v-icon>
                  </v-text-field>
                </v-layout>

                <template v-if="methodCall != 'twilio'">
                  {{/* OrigPhone, ext */}}
                  <v-layout>
                    <v-text-field
                      outlined
                      class="mr-1"
                      dense
                      label="Origin Phone"
                      prepend-inner-icon="mdi-numeric-positive-1"
                      v-model="form.origPhone"
                      id="id"
                    ></v-text-field>
                    <v-text-field
                      outlined
                      dense
                      label="Ext"
                      v-model="form.ext"
                    ></v-text-field>
                  </v-layout>
                </template>

                <v-row no-gutters>
                  <v-col cols="12" sm="6">
                    <field-ocupations
                      class="mb-5"
                      v-model="form.ocupationId"
                      :fieldProps="{ 'hide-details': true }"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <!--    Pay for Hours -->
                  <!-- <v-col cols="12" sm="3">
                <v-text-field
                  v-model="form.payXhour"
                  outlined
                  type="number"
                  dense
                  label="Pay for Hour ($)"
                ></v-text-field>
              </v-col> -->
                  <!-- Hours to work    -->
                  <!--   <v-col cols="12" sm="3">
                <v-text-field
                  v-model="form.hoursToWork"
                  outlined
                  class="mx-1"
                  type="number"
                  dense
                  label="Hours to Work (H)"
                ></v-text-field>
              </v-col> -->
                  <v-col cols="12" sm="4">
                    <ma-time-picker
                      :editable="true"
                      :starttime="startime"
                      v-model="startime"
                      label="Start time"
                      ref="timepick"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <ma-time-picker
                      class="mx-1"
                      :editable="true"
                      :starttime="endtime"
                      v-model="endtime"
                      label="End time"
                      ref="timepick"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      label="Current work time"
                      class="currentTime"
                      disabled
                      outlined
                      dense
                      v-model="showTime"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-layout>
                  <field-password
                    v-model.trim="form.password"
                    class="mr-1"
                    :fieldProps="{ autocomplete: 'off' }"
                    :fieldRequired="false"
                  />
                  <field-password
                    v-model.trim="passwordRetry"
                    retry
                    class="ml-1"
                    :fieldProps="{ autocomplete: 'off' }"
                    :fieldRequired="false"
                  />
                </v-layout>
              </v-form>
            </v-card-text>

            {{/* Alerts */}}
            <div class="px-4">
              <v-expand-transition>
                <v-alert
                  v-if="samePassword == false"
                  type="error"
                  border="left"
                  text
                  dense
                >
                  <span class="text-body-2">Passwords don't match</span>
                </v-alert>
              </v-expand-transition>
            </div>

            <v-divider />
            <v-card-actions class="justify-end">
              <v-btn color="error" width="100" rounded @click="cancelEdit">
                {{ $t("labelCancel") }}
              </v-btn>
              <v-btn
                color="primary"
                :loading="loading"
                :disabled="loading"
                width="100"
                depressed
                rounded
                @click="update"
              >
                {{ $t("labelUpdate") }}
              </v-btn>
            </v-card-actions>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <v-card-text>
              <employe-companies />
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="tab-3">
            <v-card-text>
              <edit-payments />
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";

import { FieldPassword, FieldUser } from "@/components/account";
import AllowIf from "@/components/AllowIf";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import rules from "@/components/account/rules";
import { notifyError, notifySuccess } from "@/components/Notification";
import { AxiosError } from "axios";
import { ResponseStatus } from "@/models/ResponseStatusEnum";
import Worker from "@/models/Worker";
import { omitBy } from "lodash";
import FieldOcupations from "@/components/account/FieldOcupations.vue";
import MaTimePicker from "@/components/MaTimePicker.vue";
import moment from "moment";
import { getAPI } from "@/api/axios-base";
import EmployeCompanies from "./EmployeCompanies.vue";
import EditPayments from "./components/EditPayments.vue";
export default Vue.extend({
  name: "update-employee",
  components: {
    FieldPassword,
    AllowIf,
    FieldOcupations,
    FieldUser,
    MaTimePicker,
    EmployeCompanies,
    EditPayments,
  },
  data() {
    return {
      tab: null,

      dialog: false,
      passwordRetry: "",
      formValid: false,
      rules: {
        ...rules,
        maxCaracters: (v) => v.length >= 3 || this.$t("min5Chars"),
      },
      ocupattions: [],
      loadingOcupations: false,
      editMode: false,
      startime: "",
      endtime: "",
      form: {
        email: "",
        username: "",
        password: "",
        fullname: "",
        phone: "",
        ocupationId: 0,
        rol: "",
        payXhour: 0,
        hoursToWork: 0,
        origPhone: "",
        ext: "",
        startDate: "",
        endDate: "",
      },
      uuid: "",
      loading: false,
    };
  },

  computed: {
    ...mapGetters(["isSuper", "isAdmin", "isWorker", "isDoctor"]),
    ...mapGetters({ methodCall: "getMethodCalls" }),

    passwordIsRight() {
      const { passwordRetry, form } = this;
      const same = passwordRetry == form.password;
      const notEmpty = passwordRetry.trim() != "" && form.password.trim() != "";
      // const maxLength = passwordRetry.length >= 8 && form.password.length >= 8;

      return same && notEmpty;
    },
    samePassword() {
      return this.passwordRetry.trim() == this.form.password.trim();
    },
    enableCreate() {
      return this.passwordIsRight && this.formValid;
    },

    showTime() {
      return this.startime + "-" + this.endtime;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actCreateEmployee", "actUpdateWorker"]),

    cancelEdit() {
      this.loading = false;
      this.$router.push(`/employees/details/` + this.uuid);
    },
    update() {
      let reference = Object.assign({}, this.form);
      reference.startDate = this.startime;
      reference.endDate = this.endtime;
      if (reference.startDate) {
        const h = reference.startDate.split(":")[0];
        const m = reference.startDate.split(":")[1];
        const dateStart = moment()
          .startOf("day")
          .add(Number(h), "hour")
          .add(Number(m), "minute");
        reference.startDate = dateStart.toISOString();
      }
      if (reference.endDate) {
        const h = reference.endDate.split(":")[0];
        const m = reference.endDate.split(":")[1];
        const dateStart = moment()
          .startOf("day")
          .add(Number(h), "hour")
          .add(Number(m), "minute");
        reference.endDate = dateStart.toISOString();
      }
      // Remove empties keys
      if (reference.phone != "" && reference.phone != undefined) {
        reference.phone = "+1" + reference.phone;
      }

      if (reference.payXhour != 0) {
        reference.payXhour = Number(reference.payXhour);
      }
      if (reference.hoursToWork != 0) {
        reference.hoursToWork = Number(reference.hoursToWork);
      }
      const cleanBody = omitBy(reference, (key) => key == "");

      // Validations
      if (
        this.passwordIsRight == false &&
        this.passwordRetry !== "" &&
        this.form.password !== ""
      ) {
        notifyError("The passwords don't match");
        return;
      }

      if (this.rules.isPhone(this.form.phone) !== true && this.form.phone !== "") {
        notifyError(this.rules.isPhone(this.form.phone));
        return;
      }

      if (this.rules.email(this.form.email) !== true && this.form.email !== "") {
        notifyError(this.rules.email(this.form.email));
        return;
      }

      getAPI
        .post("/users/update/" + this.uuid, cleanBody)
        .then(() => {
          notifySuccess("Employee updated succes successfully");
          this.resetForm();
          this.cancelEdit();
        })
        .catch((error) => {
          this.loading = false;

          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    resetForm() {
      this.passwordRetry = "";
      this.startime = "";
      this.endtime = "";
      this.form = {
        username: "",
        email: "",
        password: "",
        fullname: "",
        phone: "",
        ocupationId: 0,
        rol: "",
        payXhour: 0,
        hoursToWork: 0,
        origPhone: "",
        ext: "",
        startDate: "",
        endDate: "",
      };

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    init() {
      this.setToEdit();
    },
    setToEdit() {
      const uuid = this.$route.params.uuid;
      this.uuid = uuid;
      getAPI("/users/findOne/" + uuid).then((res) => {
        const employee = res.data;
        let idocupation = 0;
        if (employee.ocupation != null) {
          idocupation = employee.ocupation.id;
        }
        this.form.username = employee.username ? employee.username : "";
        this.form.ext = employee.ext ? employee.ext : "";
        this.form.email = employee.email ? employee.email : "";
        this.form.fullname = employee.fullname ? employee.fullname : "";
        this.form.phone = employee.phone != null ? employee.phone.replace("+1", "") : "";
        this.form.ocupationId = idocupation;
        this.form.rol = employee.rol;
        this.form.hoursToWork = employee.hoursToWork;
        this.form.payXhour = employee.payXhour;
        if (employee.startDate) {
          const tim = moment(employee.startDate).format("HH:mm");
          this.form.startDate = tim;
          this.startime = tim;
        }
        if (employee.endDate) {
          const tim = moment(employee.endDate).format("HH:mm");
          this.form.endDate = tim;
          this.endtime = tim;
        }

        this.uuid = employee.uuid;
        if (this.methodCall != "twilio") {
          this.form.ext = employee.ext ? employee.ext : "";
          this.form.origPhone =
            employee.origPhone != null ? employee.origPhone.replace("+1", "") : "";
        }
      });
    },
  },
});
</script>
<style lang="scss" scoped></style>

<i18n>
{
  "en": {
    "dialogTitle": "Create employee",
    "min5Chars": "Minimum 5 characters",
    "passNotMath": "Passwords don't match",
    "notificationCreateAgent": "Agent successfully created",
    "notificationCreateAdmin": "Admin successfully created",
    "notificationCompany": "Must create a company",
    "notificationConflict": "There is data that is already being used by another user.<br/> It can be <b>email</b>, <b>phone</b> or <b>username</b>."
  },
  "es": {
    "dialogTitle": "Create employee",
    "min5Chars": "Mínimo 5 caracteres",
    "passNotMath": "Las contraseñas no coinciden",
    "notificationCreateAgent": "Agente creado con éxito",
    "notificationCreateAdmin": "Administrador creado con éxito",
    "notificationCompany": "Debe crear una compañía",
    "notificationConflict": "Existen datos que ya están siendo usado por otro usuario.<br/> Puede ser el <b>correo</b>, el <b>teléfono</b> o el <b>nombre de usuario</b>."
  }
}
</i18n>
